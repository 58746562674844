import React, { useState } from 'react';
import './Box.css';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

const Box = () => {
    const [counterOn, setCounterOn] = useState(false);
  return (
    <section className="container">
    <div className='about___boxes grid'>

        <div className='about___box'>
            <i className='about___icon fa fa-star'></i>
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={()=> setCounterOn(false)}>
            <div>
            <h4 className='about___title'>{counterOn && <CountUp start={0} end={2428} duration={1} delay={0} />}</h4> 
            <span className='about___subtitle'>Projects Completed</span>
        </div>
        </ScrollTrigger>
    </div>
    <div className='about___box'>
            <i className='about___icon fa fa-globe'></i>
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={()=> setCounterOn(false)}>
            <div>
            <h4 className='about___title'>{counterOn && <CountUp start={0} end={30} duration={1} delay={0} />}</h4> 
            <span className='about___subtitle'>Countries Serving</span>
        </div>
        </ScrollTrigger>
    </div>
    <div className='about___box'>
            <i className='about___icon fa fa-users'></i>
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={()=> setCounterOn(false)}>
            <div>
            <h4 className='about___title'>{counterOn && <CountUp start={0} end={1200} duration={1} delay={0} />}</h4> 
            <span className='about___subtitle'>Satisfied Clients</span>
        </div>
        </ScrollTrigger>
    </div>
    <div className='about___box'>
            <i className='about___icon fa fa-life-ring'></i>
            <div>
            <h4 className='about___title'>24/7</h4>
            <span className='about___subtitle'>Support</span>
        </div>
    </div>
    </div>
    </section>
  )
}

export default Box