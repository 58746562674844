import React from "react";
import img1 from "../../images/mission.jpg";
import { Fade } from "react-reveal";
import Nav2 from "../../Components/Navbar2/Nav2";
import ScrollTop from '../../Components/ScrollTop/ScrollTop';
import Footer from "../../Components/Footer/Footer";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <>
     <ScrollTop preventScrollReset getKey={location => { return location.pathname; }}/>
      <Nav2 />
      <div className="container">
        <div className="policy-wrapper home-wrapper-2">
          <div className="about-section" id="services">
            <div className="about-title Home-title career-header">
                <h1>#About-Us</h1>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Fade left>
                  <div className="about-content-section">
                    <div className="about-content">
                      <h3>Mission & Vision</h3>
                      <p>
                      We are dedicated to empowering traders with advanced tools and resources 
                      for Online Trading. Our mission is to provide you a user-friendly experience, 
                      unparalleled customer support, and the highest standards of security to empower 
                      our clients to trade with confidence. At Havana Tech, we are committed to driving 
                      innovation and helping traders navigate the complexities of the financial markets with confidence.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Fade right>
                  <img src={img1} className="aboutus-image" alt="about" />
                </Fade>
              </div>
            </div>
          </div>
          <div className="header">
            <h1>Our Expertise</h1>
            <section>
              <div className="about___data">
                <div className="about___skils grid">
                  <div className="row skills___data">
                    <Fade left transitionDuration={90}>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 skills___titles">
                        <h3 className="skills___name">Commodities</h3>
                        <div className="row expect">
                          <div className="col-10 skills___bar">
                            <span className="skills___percentage commodities"></span>
                          </div>
                          <div className="col-2">
                            <span className="skills___number">85%</span>
                          </div>
                        </div>
                      </div>
                    </Fade>
                    <Fade left transitionDuration={90}>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 skills___titles">
                        <h3 className="skills___name">Forex</h3>{" "}
                        <div className="row expect">
                          <div className="col-10 skills___bar">
                            <span className="skills___percentage forex"></span>
                          </div>
                          <div className="col-2">
                            <span className="skills___number">80%</span>
                          </div>
                        </div>
                      </div>
                    </Fade>
                    <Fade left transitionDuration={90}>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 skills___titles">
                        <h3 className="skills___name">IPO's</h3>
                        <div className="row expect">
                          <div className="col-10 skills___bar">
                            <span className="skills___percentage ipo"></span>
                          </div>
                          <div className="col-2">
                            <span className="skills___number">90%</span>
                          </div>
                        </div>
                      </div>
                    </Fade>
                    <Fade left transitionDuration={78}>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 skills___titles">
                        <h3 className="skills___name">Shares</h3>
                        <div className="row expect">
                          <div className="col-10 skills___bar">
                            <span className="skills___percentage shares"></span>
                          </div>
                          <div className="col-2">
                            <span className="skills___number">78%</span>
                          </div>
                        </div>
                      </div>
                    </Fade>
                    <Fade left transitionDuration={75}>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 skills___titles">
                        <h3 className="skills___name">Indices</h3>
                        <div className="row expect">
                          <div className="col-10 skills___bar">
                            <span className="skills___percentage indices"></span>
                          </div>
                          <div className="col-2">
                            <span className="skills___number">75%</span>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;