import React, { useState } from 'react';
import Work1 from '../../images/fundamental.png';
import Work2 from '../../images/technical.png';
import Work3 from '../../images/cod.png';
import "./operation.css";

const Menuu = [
    {
      id: 1,
      image: Work1,
      description: "Fundamental analysis in trading involves assessing an asset's true value by considering economic indicators and interest rates, aiding investors in making informed decisions.",
      title: "Fundamental Analysis: Assessing Investment Value through Economic Factors",
    },
    {
      id: 2, 
      image: Work2,
      description: "Technical analysis in trading uses historical price data to predict future trends and identify trading opportunities based on chart analysis and statistical tools.",
      title: "Technical Analysis: Predicting Future Price Trends from Historical Data",
    },
    {
      id: 3,
      image: Work3,
      description: "COT report analysis reveals market sentiment and trends by examining the positions of different trader categories in the futures market, aiding traders in making well-informed decisions.",
      title: "COT Report Analysis: Unveiling Market Sentiment and Trends",
    },
  ];

const Portfolio = () => {
  const [items] = useState(Menuu);

  return (
    <section className='work container section opr-header' id='work'>
      <h1 className='section___title'>Base of Operations</h1>
      <p className="opr">
            A resilient toolkit for in-depth market analysis, strategic expansion, and adept 
            transaction execution forms the cornerstone of success in this domain. The seamless 
            integration of these key elements is paramount for attracting participants and achieving 
            sustained excellence in the dynamic business landscape.
          </p>
      <div className='work___container grid'>
        {items.map((elem) => {
          const{ id, title, image, description} = elem;
          return (
            <div className='work___card opr' key={id}>
            <div>
            <img src={image} alt='' className='work___img' />
            <p>{description}</p>
            <div className='work___mask'>
            <h3 className='work___title'>{title}</h3>
            </div>
            </div>
            </div>
          ) 
        })}
      </div>
      </section>
  )
}

export default Portfolio