import React from 'react';
import "./Pricing.css";
import Image1 from '../../images/price-1.svg';
import Image2 from '../../images/price-2.svg';
import Image3 from '../../images/price-3.svg';

const Pricing = () => {
  return (
    <section className='pricing container section header'>
      <h1 className='section___title'>Pricing Plan</h1>
      <div className='pricing___container grid'>

        <div className='pricing___item pricing___services'>
          <img src={Image1} alt='' className='pricing___img' />
          <h3 className='pricing___plan'>Basic</h3>
          <p className='pricing___title'>Offers 5-7 daily trading signals, each subjected to thorough analysis</p><hr />
          <p className='pricing___title'>Continuous market strategy updates tailored for You</p><hr />
          <p className='pricing___title'>Access multiple traders online to discuss and resolve queries instantly</p><hr />
          <p className='pricing___title'>Pending orders of the market would also be posted separately from time to time</p>
          <p className='pricing___support'>Email Support</p>
          <h3 className='price'>
          <em>$</em>99<span>Month</span>
          </h3>
        </div>

        <div className='pricing___item best'>
        <span className='badge'>Recommended</span>
          <img src={Image2} alt='' className='pricing___img' />
          <h3 className='pricing___plan'>Premium</h3>
          <p className='pricing___title'>Expertise in structural, momentum, and trend trading</p><hr />
          <p className='pricing___title'>Our offering encompasses understanding and implementing trading psychology, strategies</p><hr />
          <p className='pricing___title'>Free access to the Premium Market signals</p><hr />
          <p className='pricing___title'>One-to-one communication with the analyst via Zoom or Teams for dedicated attention</p>
          <p className='pricing___support'>Mon-Fri support</p>
          <h3 className='price'>
          <em>$</em>149<span>Month</span>
          </h3>
        </div>

        <div className='pricing___item pricing___services'>
          <img src={Image3} alt='' className='pricing___img' />
          <h3 className='pricing___plan'>Ultimate</h3>
          <p className='pricing___title'>Goes beyond the basics, delving into advanced trading techniques</p><hr />
          <p className='pricing___title'>Explores trading psychology, including cognitive biases and emotional control</p><hr />
          <p className='pricing___title'>Each session includes a 10-minute slot for questions and answers</p><hr />
          <p className='pricing___title'>Free access to the Premium Market signals and analysis portal for 4 weeks</p>
          <p className='pricing___support'>24/7 support</p>
          <h3 className='price'>
          <em>$</em>199<span>Month</span>
          </h3>
        </div>

      </div>
    </section>
  )
}

export default Pricing