import React from "react";
import {Container, Row, Col, UncontrolledCarousel } from "reactstrap";
import './Carousel.css'

const items = [
  {
    src: require("../../images/img-1.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("../../images/img-2.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("../../images/img-3.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
];

class Carousel extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped background">
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
            <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right imag active">
                <UncontrolledCarousel items={items}/>
                </div>
              </Col>
              <Col className="mb-lg-0 led" lg="5">
                <h1 className="text-white font-weight-light">
                Investments Strength
                </h1>
                <p className="text-white mt-4">
                Allow traders to leverage their positions, which means you can control a more substantial position with a relatively small amount of capital.
                It enable traders to profit from both rising and falling markets.
                </p>
                <a href='/' className='hotBeans'><b>Explore More</b></a>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Carousel;