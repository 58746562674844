import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Nav2 from '../../Components/Navbar2/Nav2';
import Footer from '../../Components/Footer/Footer';
import ScrollTop from '../../Components/ScrollTop/ScrollTop';

import "./ContactUs.css";
import { useState } from "react";

  const Contact = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
     .sendForm(
        "service_jf10zo2",
        "template_wf8nbfo",
        form.current,
        "xPjZURlCbEwijk7kf",
       )
    .then(
             (result) => {
               console.log(result.text);
               console.log("message sent");
                toast.success("Thank you for reaching us! Our customer representative will respond you shortly.", {
               position:"top-center"
          });
             },
             (error) => {
               console.log(error.text);
             }
           );
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  }

  return (
    <>
    <ScrollTop preventScrollReset getKey={location => { return location.pathname; }}/>
    <Nav2 />
      <section className="contact container section">
      <div className="career-header">
        <h1>Get In Touch</h1>
        </div>
        <div className="contact container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1276.4433696598853!2d55.2654633069759!3d25.192190962343833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f429391bb0f2b%3A0x824de1560a847116!2sAspect%20Tower!5e0!3m2!1sen!2s!4v1699362225883!5m2!1sen!2s"
          width="600"
          height="450"
          className="border-0 w-100"
          title="map"
          AllowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

        <div className="contact___container grid">
          <div className="contact___info">
            <h3 className="contact___title">Let's talk about everything!</h3>
            <p className="contact___details">Reach out to us via Email!</p>
          </div>
          <form action="" className="contact___form" ref={form} onSubmit={handleSubmit} >
            <div className="contact___form-group">
              <div className="contact___form-div">
                <input
                id="name"
                  type="text"
                  className="contact___form-input"
                  placeholder="Full Name"
                  required="true"
                  name="to_name"
                  onChange={handleChange}
                  value={formData.name}
                />
              </div>
              <div className="contact___form-div">
                <input
                 id="email"
                  type="email"
                  className="contact___form-input"
                  placeholder="Your Email"
                  pattern="[a-zA-Z0-9._]+@gmail\.com$"
                  required="true"
                  unique="true"
                  name="from_name"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="contact___form-div">
              <input
               id="subject"
                type="text"
                className="contact___form-input"
                placeholder="Insert Your Subject"
                required="true"
                name="to_subject"
                onChange={handleChange}
                value={formData.subject}
              />
            </div>
            <div className="contact___form-div contact___form-area">
              <textarea
               id="message"
                cols="30"
                rows="10"
                className="contact___form-input"
                placeholder="Write your Message"
                required="true"
                name="message"
                onChange={handleChange}
                value={formData.message}
              />
            </div>
            <button className="contact-btn" name="submit" type="submit">Send Message</button>
          </form>
        </div>
        <ToastContainer />
      </section>
      <Footer />
    </>
  );
};

export default Contact;