import React from 'react';
import "./Platform.css";
import Image1 from "../../images/MT4.png";
import Image2 from "../../images/MT5.png";
import Image3 from "../../images/web.png";

const data = [
  {
    id: 1,
    image: Image1,
    title: "MT4",
    description:
    "MetaTrader 4 is a popular trading platform for forex and CFD trading. It offers technical analysis indicators & automated trading capabilities."
  },
  {
    id: 2,
    image: Image2,
    title: "MT5",
    description:
    "MT5 builds upon MT4's features, offering additional asset classes like stocks and commodities. It enhanced algorithmic trading capabilities.",
  },
  {
    id: 3,
    image: Image3,
    title: "Web Trading",
    description:
    "Web Trading offers convenience and accessibility, allowing traders to trade through a web browser without installing any software.",
  },
];

const Services = () => {
  return <section className='services container section header' id='platform'>
  <h1 className='section___title'>The Solution We've Built</h1>
  <div className='services___container grid'>
    {data.map(({id, image, title, description}) =>{
      return (
        <div className='services___card' key={id}>
        <img src={image} alt="" className='services___img'/>
        <h3 className='services___title'>{title}</h3>
        <p className='services___description'>{description}</p>
        </div>
      )
    })}
  </div>
  </section>
}

export default Services