import React from 'react';
import Platform from '../Platform/Platform';
import Product from '../Product/Product';
import Services from '../Services/Service';
import Presentation from '../Presentation/Presentation';
import Footer from '../Footer/Footer';
import ScrollToTop from 'react-scroll-to-top';
import Contact from '../Subscribe/Subscribe';
import Navbar from '../Navbar1/Navbar';
import Box from '../Box/Box';
import Carousel from '../Carousel/Carousel';
import Operation from '../Operation/Operation';
import './Home.css';
import Pricing from '../Pricing/Pricing';
import {NavLink} from 'react-router-dom';
function Header() {
  return (
    <>
    <div id='main'>
    <Navbar />
    <div className='name'>
    <h1><span>Unleash Your Financial Potential</span><br/> with Trading CFD's</h1>
    <p className='details'>Dive into Global Financial Markets with Confidence<br />Are You Ready to Navigate the World of<br />Global Finance?</p>
    <NavLink to="/ContactUs" className='coolBeans'><b>Reach Us</b></NavLink>
    </div>
    </div>
    <div className="App">
    <Platform />
    <Presentation />
    <Product />
    <Box />
    <Services />
    <Carousel />
    <Operation />
    <Pricing />
    <Contact />
    <Footer />
    <ScrollToTop Smooth size='20px'/>
    </div>
    </>
  )
}

export default Header