import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import AboutUs from "./Screen/AboutUs/AboutUs";
import Careers from "./Screen/Careers/Careers";
import ContactUs from "./Screen/ContactUs/ContactUs";
import "./index.css";

function App() {
  return (
    <HashRouter>
      <div className="grid-container">
        <main>
          <div className="theme-btn">
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Careers" element={<Careers />} />
            <Route path="/ContactUs" element={<ContactUs />} />
          </Routes>
        </main>
      </div>
    </HashRouter>
  );
}

export default App;