import React, { useState } from "react";
import toplogo from "../../images/toplogo.png";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import "./Navbar.css";

function Navbar() {
  const [nav, setnav] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 700) {
      setnav(true);
    } else {
      setnav(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  return (
    <nav className={nav ? "nav active" : "nav"}>
      <a href="/" className="logo">
        <img src={toplogo} alt="" />
      </a>
      <input type="checkbox" className="menu-btn" id="menu-btn" />
      <label className="menu-icon" for="menu-btn">
        <span className="nav-icon"></span>
      </label>
      <ul className="menu">
        <li>
          <a href="/" className="active">
            HOME
          </a>
        </li>
        <li>
        <Link to="#services" smooth>Services</Link>
        </li>
        <li>
        <Link to="#platform" smooth>Platform</Link>
        </li>
        <li>
        <Link to="#product" smooth>Products</Link>
        </li>
        <li>
        <NavLink to="/Careers">Careers</NavLink>
        </li>
        <li>
        <NavLink to="/ContactUs">Contact</NavLink>
        </li>
        <li>
        <NavLink to="/AboutUs">About Us</NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;