import React from 'react';
import {NavLink} from 'react-router-dom';
import './Subscribe.css';

function Contact() {
  return (
  <div id='subscribe'>     
    <h3>Send me Mail!</h3>
    <div className='subscribe-input'>
        <input type='email' placeholder='example@gmail.com' required="true"/>
        <NavLink to="/ContactUs"><b>Subscribe</b></NavLink>
    </div>
    </div>
  )
}

export default Contact