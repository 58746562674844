import React from 'react';
import { HiTemplate } from 'react-icons/hi';
import {PiCertificateFill } from 'react-icons/pi';
import {GrBitcoin} from 'react-icons/gr';
import {RiStockLine} from 'react-icons/ri'
import {AiOutlineStock} from 'react-icons/ai';
import {TbLayersLinked} from 'react-icons/tb';
import Fade from "react-reveal/Fade";
import './Product.css';

function Product() {
  return (
    <div>
      <div className="policy-wrapper home-wrapper-2 container" id='product'>
        <div className="careers-section">
            <div className="header">
            <h1>Our Offerings</h1>
            </div>
            <div className="careers">
              <div className="careers-row">
              <Fade left>
                <div className="careers-services">
                  <GrBitcoin className="careers-icon" />
                  <h4>Crpto</h4>
                  <p>
                  Cryptography operates on decentralized blockchain technology, enabling secure and peer-to-peer 
                  transactions.
                  </p>
                </div>
                </Fade>
                <Fade top>
                <div className="careers-services">
                  <HiTemplate className="careers-icon" />
                  <h4>Commodities</h4>
                  <p>
                  Agricultural products like Energy Resources, Precious Metals that 
                  can be bought and sold on various exchanges.
                  </p>
                </div>
                </Fade>
                <Fade right>
                <div className="careers-services">
                  <RiStockLine className="careers-icon" />
                  <h4>Forex</h4>
                  <p>
                  Speculate on currency 
                  exchange rate fluctuations and profit from the differences in values between pairs of currencies.
                  </p>
                </div>
                </Fade>
                <Fade left>
                <div className="careers-services">
                  <TbLayersLinked className="careers-icon" />
                  <h4>Bonds</h4>
                  <p>
                  Debt securities issued by governments or corporations to raise capital. Lending money in exchange for periodic interest.
                  </p>
                </div>
                </Fade>
                <Fade bottom>
                <div className="careers-services">
                  <AiOutlineStock className="careers-icon" />
                  <h4>Shares</h4>
                  <p>
                  When you buy shares of a company, you become a shareholder 
                  .It allows investors to buy and sell these stakes.
                  </p>
                </div>
                </Fade>
                <Fade right>
                <div className="careers-services">
                <PiCertificateFill className="careers-icon" />
                <h4>Indices</h4>
                  <p>
                  Benchmarks that track the performance of stocks in financial market.
                  Investors use indices to gauge market trends.
                  </p>
                </div>
                </Fade>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Product;