import React from 'react';
import './Presentation.css';
import { MdFreeCancellation } from 'react-icons/md';

const pres2 = () => {
  return (
    <>
    <section className="container" id="">
    <div className='contain header'>
    <div class="wp-block-themepunch-newsletter sr-newsletter-block sr-blogbox red description">
    <MdFreeCancellation className="careers-icon" />
    <h1 class="wp-block-heading" id="h-design-visually-attractive-and-high-performing-websites-without-writing-a-line-of-code">
    HAVANA TECH will never?</h1>
    <p>Offer guarantees of substantial financial gains, Claim that trading will dramatically alter your life, Make unrealistic 
    and exaggerated promises, Display customer testimonials that can be misleading, Solely emphasize the potential rewards without considering associated risks and responsibilities.</p>
    </div>
    </div>
    </section>
    </>
  )
}

export default pres2