import React from 'react';
import { BsLinkedin } from "react-icons/bs";
import { SiGmail } from "react-icons/si";
import {TbPhoneCall} from 'react-icons/tb';
import {HiMail} from 'react-icons/hi';
import {ImLocation2, ImWhatsapp} from 'react-icons/im';
import lowerlogo from "../../images/footerlogo.png";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <section id="footer" className="footer-section">
        <div class="container">
          <div class="row text-center text-xs-center text-sm-left text-md-left">
            <div class="col-xs-12 col-sm-4 col-md-3">
              <div className="footer-container">
                <a href="/" className="lowerlogo">
                <img src={lowerlogo} alt="" />
                </a>
                <p>
                Trusted partner in the dynamic world of Trading.
                Committed to evolving for your success with top-notch services.
                </p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
              <h5>Who we are</h5>
              <ul class="list-unstyled quick-links">
                <li>
                <NavLink to="/"><i class="fa fa-angle-right"></i>Home</NavLink>
                </li>
                <li>
                  <a href="#/">
                  <i class="fa fa-angle-right"></i>FAQ's
                  </a>
                </li>
                <li>
                  <NavLink to="/Careers"><i class="fa fa-angle-right"></i>Careers</NavLink>
                </li>
                <li>
                  <a href="#/">
                  <i class="fa fa-angle-right"></i>Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
              <h5>What we do</h5>
              <ul class="list-unstyled quick-links">
                <li>
                  <a href="#/">
                  <i class="fa fa-angle-right"></i>Product
                  </a>
                </li>
                <li>
                  <a href="#/">
                  <i class="fa fa-angle-right"></i>Services
                  </a>
                </li>
                <li>
                  <a href="#/">
                  <i class="fa fa-angle-right"></i>Platform
                  </a>
                </li> 
                <li>
                  <a href="#/">
                  <i class="fa fa-angle-right"></i>Testimonials / Reviews
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
              <h5>Connect with Us</h5>
              <ul class="list-unstyled address-link">
                <li>
                <NavLink to="callto:97145635264"><TbPhoneCall className="cart-icon3"/>(+971)-456 352 64</NavLink>
                </li>
                <li>
                <NavLink to="mailto:info@havanatechpl.com"><HiMail className="cart-icon3"/>info@havanatechpl.com</NavLink>
                </li>
                <li>
                <NavLink to="/ContactUs"><ImLocation2 className="cart-icon3"/>Office#3808, Aspect Tower, Business Bay Dubai</NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className='row d-flex align-items-center'>
          <hr className="my-3 hr" />
          <div className="col-md-7 col-lg-8 text-center text-md-start">
                  <div className="p-3 copyright"> © {new Date().getFullYear()}{" "}
                  <a href="/">Havana Tech</a> . </div>
                </div>
                <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                <a href="mailto:info@havanatechpl.com" target="_blank" rel="noreferrer">
                  <SiGmail className="cart-icon2"/>
                </a>
                <a href="https://www.linkedin.com/company/havanatech" target="_blank" rel="noreferrer">
                  <BsLinkedin className="cart-icon2"/>
                </a>
                <NavLink to="https://api.whatsapp.com/send?phone=97145635264" rel="noreferrer" target="_blank"><ImWhatsapp className="cart-icon2"/></NavLink>
              </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default Footer;